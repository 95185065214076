<template>
  <v-row class="mt--20">
    <v-col cols="12">
      <ul class="brand-style-2">
        <li v-for="(brand, i) in brandImages" :key="i">
          <a :href="brand.url" target="_blank">
            <img
              :class="`partner-logo-${i + 1}`"
              :src="brand.src"
              alt="RHS24 accreditation images"
            />
          </a>
        </li>
      </ul>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      brandImages: [
        {
          src: require("../assets/images/Partners/rehoboth24_iso.jpg"),
          url: "https://www.qmsuk.com/iso-standards/iso-9001",
        },
        {
          src: require("../assets/images/Partners/rehoboth24_rec_member.jpg"),
          url: "https://www.rec.uk.com/join",
        },
        {
          src: require("../assets/images/Partners/rehoboth24_ico.png"),
          url: "https://ico.org.uk/",
        },
      ],
    };
  },
};
</script>
<style scoped>
/* .partner-logo-2 {
  width: 180px !important;
} */
</style>
