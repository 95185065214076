<template>
  <div>
    <v-navigation-drawer
      class="hidden-md-and-up header-one-page-nav-drawer"
      v-model="drawer"
      fixed
      width="320"
    >
      <v-list-item class="pa-5 align-start">
        <v-spacer></v-spacer>
        <div class="logo-drawer">
          <img
            src="../assets/images/logo/rhs24_logo_main_002.png"
            alt="brand-image"
          />
        </div>
        <v-spacer></v-spacer>
        <!-- <v-btn
          class="close-icon"
          icon
          @click="drawer = !drawer"
          v-html="iconSvg(closeIcon)"
        >
        </v-btn> -->
        <v-btn @click="drawer = !drawer" fab dark small color="#FF6F00">
          <v-icon dark> mdi-close </v-icon>
        </v-btn>
      </v-list-item>
      <scrollactive
        active-class="v-btn--active"
        bezier-easing-value=".5,0,.35,1"
        :offset="71"
      >
        <v-list>
          <template v-for="item in menuItems">
            <v-list-group
              v-if="item.parent"
              :key="item.title"
              v-model="item.active"
              :ripple="false"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                link
                v-for="child in item.children"
                :to="child.to"
                :key="child.title"
                :ripple="false"
                class="scrollactive-item"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="child.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-item
              v-else
              :key="item.title"
              :to="item.to"
              :ripple="false"
              link
              class="scrollactive-item"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <!-- End mobile menu sidebar item list -->
        </v-list>
      </scrollactive>
    </v-navigation-drawer>
    <!-- End sidebar mobile menu -->

    <v-app-bar
      dark
      color="transparent"
      fixed
      elevate-on-scroll
      class="header-one-page-nav"
    >
      <router-link to="/" class="logo">
        <slot name="logo"></slot>
        <slot name="logo-dark"></slot>
      </router-link>
      <!-- End brand logo -->
      <v-spacer></v-spacer>

      <v-toolbar-items class="hidden-xs-only hidden-sm-only height-auto">
        <scrollactive
          active-class="v-btn--active"
          bezier-easing-value=".5,0,.35,1"
        >
          <template v-for="item in menuItems">
            <v-menu
              v-if="item.parent"
              :key="item.title"
              open-on-hover
              bottom
              min-width="240"
              offset-y
              transition="scroll-y-reverse-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn :ripple="false" text v-bind="attrs" v-on="on">
                  {{ item.title }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  link
                  v-for="child in item.children"
                  :key="child.title"
                  :to="child.to"
                  class="scrollactive-item"
                >
                  <v-list-item-title>{{ child.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              :key="item.title"
              v-else
              link
              :ripple="false"
              text
              :to="item.to"
              class="scrollactive-item"
              >{{ item.title }}</v-btn
            >
          </template>
        </scrollactive>
      </v-toolbar-items>
      <!-- End header menu item -->
      <v-btn
        icon
        :ripple="false"
        class="ma-0 pa-0 hidden-md-and-up menu_icon"
        @click="drawer = !drawer"
        v-html="iconSvg(icon)"
      >
      </v-btn>
      <!-- End mobile menu icon -->
    </v-app-bar>
    <!-- End top header navbar -->
  </div>
</template>

<script>
import feather from "feather-icons";
export default {
  data: () => ({
    drawer: false,
    menuItems: [
      { title: "Home", to: "/", parent: false },
      { title: "About us", to: "/about-us", parent: false },
      {
        title: "Our Services",
        parent: true,
        children: [
          { title: "Staffing Services", to: "/staffing-services" },
          { title: "Domiciliary Care", to: "/domiciliary-care" },
          { title: "Supported Living", to: "/supported-living" },
        ],
      },
      {
        title: "Jobs",
        parent: true,
        children: [
          { title: "Apply Online", to: "/apply-online" },
          { title: "Download", to: "/downloads" },
        ],
      },
      { title: "Contact us", to: "/contact-us", parent: false },
    ],
    icon: "menu",
    closeIcon: "x",
  }),
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
