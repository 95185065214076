<template>
  <v-row>
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      v-for="(address, i) in addressContent"
      :key="i"
    >
      <div class="rn-address" :class="address.isAddress">
        <div class="icon" v-html="iconSvg(address.icon)"></div>
        <div class="inner">
          <h4 class="heading-title">{{ address.title }}</h4>
          <p>
            <a :href="address.to1" v-html="address.info1"></a><br />
            <a :href="address.to2" v-html="address.info2"></a>
          </p>
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import feather from "feather-icons";
export default {
  data() {
    return {
      addressContent: [
        {
          icon: "headphones",
          title: "Contact Phone Numbers",
          info1: "(0116) 319-5972",
          info2: "(0796) 001-9170",
          to1: "tel:(0116) 319-5972",
          to2: "tel:(0796) 001-9170",
        },
        {
          icon: "mail",
          title: "Our Email Address",
          info1: "info@rhs24.cloud",
          to1: "mailto:info@rhs24.cloud",
        },
        {
          icon: "map",
          title: "Our Location",
          info1:
            "12 Briton Street<br>LE3 0AA<br>Leicester United Kingdom",
          isAddress: "addres-last",
        },
      ],
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>

<style lang="scss">
.addres-last {
  p {
    pointer-events: none;
  }
}
</style>
