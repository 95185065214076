<template>
  <v-row>
    <v-col sm="6" cols="12" v-for="(item, i) in serviceContent" :key="i">
      <v-card shaped elevation="12" class="service-custom-wrapper">
        <v-img :src="item.src"></v-img>
        <v-card-title style="color: #008080">{{ item.title }}</v-card-title>
        <v-card-text class="text-left pb--30" v-html="item.desc"> </v-card-text>
        <!--  <v-card-actions class="justify-center">
          <v-btn :to="item.url" color="orange accent-2" text>
            read more...S
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import feather from "feather-icons";
export default {
  data() {
    return {
      serviceContent: [
        {
          src: require("../assets/images/stockpics/healthcare_small/healthcare_003.jpg"),
          title: "Qualified Nurses",
          desc: `<p>RHS24 Ltd sources professional Registered General Nurses and supplies these staff to nursing, residential and respite care homes on short, 
          long and fixed terms. We also help in recruiting permanent professional qualified nurses by sourcing, screening and arranging interviews. Our operations cover major cities in the UK.
          <p>Our professionals are available to work different shift patterns as required in order to meet the needs of our clients.
          Our team of Nurses get regular mandatory internal training to ensure they are staying up to date with current professional standards set by government and professional bodies.`,
        },
        {
          src: require("../assets/images/stockpics/healthcare_small/healthcare_002.jpg"),
          title: "Professional Healthcare",
          desc: `<p>We have a large bank of qualified and experienced Healthcare Assistants staff who can be booked on an ad-hoc or ongoing basis, 24 hours a day, 7 days a week.
          <p>We also offer managed healthcare staffing solutions providing bespoke services, tailored exactly to the needs of each client.
          <p>Our professional Healthcare Assistants are regularly trained to ensure that they are serving our clients with excellence thereby providing a great service user experience.
          `,
        },
        {
          src: require("../assets/images/stockpics/healthcare_small/healthcare_006.jpg"),
          title: "Support Workers",
          desc: `<p>At RHS24 Ltd we keep a bank of Professional Support Workers that work with Children and Adults in care. Our staff can be booked anytime to serve our clients. 
          They are also regularly trained to ensure high standards kept and are maintained.
          `,
        },
        {
          src: require("../assets/images/stockpics/healthcare_small/healthcare_004.jpg"),
          title: "Training",
          desc: `<p>All of our agency workers receive the appropriate induction training and annual updates of which most are face-to-face training while the rest are online based.
          <p>We ensure that all agency workers are well-trained and competent.
          <p>The training that they receive includes: Safely Moving and Handling, Dementia Awareness, Risk Assessments, Lone working, Basic Life Support, Safeguarding Adults, Fire Safety, COSHH, Epilepsy Awareness and Medication Awareness.
          `,
        },
        {
          src: require("../assets/images/stockpics/healthcare_small/healthcare_007.jpg"),
          title: "Revalidation",
          desc: `<p>At RHS 24 we encourage and support Registered Nurses who work with us to ensure that they are always up to date with their validation with the Nursing & Midwifery Council (NMC). This also means that they remain fit to practice.
          Revalidation promotes greater professionalism among nurses and midwives and also improves the quality of care that patients receive by encouraging reflection on nurses' and midwives' practice against the revised NMC Code.
          `,
          url: "/room-let-housing",
        },
        {
          src: require("../assets/images/stockpics/healthcare_small/healthcare_010.jpg"),
          title: "Professional, Efficient and Personable",
          desc: `<p>Everything about RHS24 Ltd stands for quality, whether that's the services we provide or the staff we employ.
          We want to make sure that our staff can offer the very best service in their work, no matter how small the task.
          <p>From assisting with cleaning and cooking to more sensitive roles, such as, working with dementia patients, our staff are consistently professional, friendly and efficient.
          <p>RHS24 Ltd is currently able to offer an extensive number of services to meet our clients' needs by supplying them professional and skilled staff.
          `,
        },
      ],
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
<style scoped>
.v-card__title {
  word-break: break-word !important;
}
</style>
