<template>
  <footer class="footer-area footer-style-01 app-footer">
    <div class="footer-wrapper ptb--70 im-separator">
      <v-container class="container">
        <v-row>
          <v-col align-self="center" lg="3" md="3" sm="6" cols="12">
            <div class="ft-text">
              <div class="logo">
                <a href="/"><img :src="logo" alt="RHS24" /></a>
              </div>
            </div>
          </v-col>
          <v-col
            align-self="center"
            lg="3"
            md="3"
            sm="6"
            cols="12"
            class="mt_mobile--40"
          >
            <div class="footer-link">
              <h4>Contact us</h4>
              <p>
                Head Office<br>
                12 Briton Street<br>
                LE3 0AA<br>
                Leicester<br>
                United Kingdom
              </p>
              <ul class="ft-link">
                <li>Tel: <a href="tel:07984606492">(0116) 319-5972</a></li>
                <li>Mobile: <a href="tel:07534894711">(0796) 001-9170</a></li>
                <li>
                  Email:
                  <a href="mailto:info@rhs24.cloud">info@rhs24.cloud</a>
                </li>
              </ul>
            </div>
          </v-col>
          <v-col lg="3" md="3" sm="6" cols="12" class="mt_md--40 mt_sm--40">
            <div class="footer-link">
              <h4>Opening times</h4>
              <p>
                Mon - Fri:<br />
                09 :00am - 17 :00pm<br />
                Sat: Closed<br />
                Sun: Closed<br />
              </p>
              <div class="social-share-inner mt--20">
                <ul
                  class="
                    social-share
                    social-style--2
                    d-flex
                    justify-content-start
                    liststyle
                    mt--15
                  "
                >
                  <li v-for="(social, i) in socialList" :key="i">
                    <a :href="social.url" target="_blank">
                      <i class="fab" :class="social.icon"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </v-col>
          <v-col lg="3" md="3" sm="6" cols="12" class="mt_md--40 mt_sm--40">
            <div class="footer-link">
              <h4>Our Services</h4>
              <ul class="ft-link">
                <li>
                  <router-link to="/staffing-services">Staffing Services</router-link>
                </li>
                <li>
                  <router-link to="/domiciliary-care"
                    >Domiciliary Care</router-link
                  >
                </li>
                <li><router-link to="/supported-living">Supported Living</router-link></li>
                <li>
                  <router-link to="/apply-online"
                    >Apply Online</router-link
                  >
                </li>
                <li>
                  <router-link to="/about-us"
                    >About us</router-link
                  >
                </li>
                <li>
                  <router-link to="/">Home</router-link>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="footer-wrapper ptb--20 im-separator">
      <v-container class="container">
        <v-row>
          <v-col md="5" offset-md="1" cols="12">
            <span class="app-company">
              RHS24 Care | Company number: 09432241
            </span>
          </v-col>
          <v-col md="5" offset-md="1"  cols="12">
            <div class="footer-link">
              <ul class="ft-link">
                <li>
                  <a href="https://login.microsoftonline.com" target="_blank"
                    >Staff E-mails</a
                  >
                </li>
              </ul>
            </div>
          </v-col>         
        </v-row>
      </v-container>
    </div>
    <div class="im-call-to-action-area ptb--20">
      <v-container>
        <v-row class="align-center">
          <v-col sm="10" offset-sm="1" cols="12">
            <p class="dev-contact">
              For your software needs contact
              <span style="color: #0094ff">programmer@softmighty.com</span> :
              mobile-apps, web-apps, websites, data-driven apps, consultancy and
              IT Strategy advisory services.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      logo: require("../assets/images/logo/rhs24_logo_main_002.png"),
      socialList: [
        {
          icon: "fa-facebook-f",
          url: "javascript:void(0)",
        },
        {
          icon: "fa-linkedin-in",
          url: "javascript:void(0)",
        },
        {
          icon: "fa-twitter",
          url: "javascript:void(0)",
        },
        {
          icon: "fa-instagram",
          url: "javascript:void(0)",
        },
      ],
    };
  },
};
</script>
<style>
.app-footer {
  background-color: #134233;
}
.app-address {
  color: #bfbdbd;
  font-size: 16px !important;
  line-height: 28px !important;
  margin-bottom: 30px;
}
.app-company {
  color: #bfbdbd;
  font-size: 14px;
}
</style>
