var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"formRef",attrs:{"tag":"form"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100, required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"blue lighten-1","label":"Your name *","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.enq_PersonName),callback:function ($$v) {_vm.$set(_vm.fdata, "enq_PersonName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.enq_PersonName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100, required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"blue lighten-1","label":"Mobile / Telephone number *","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.enq_Mobile),callback:function ($$v) {_vm.$set(_vm.fdata, "enq_Mobile", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.enq_Mobile"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 100, email: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"blue lighten-1","label":"E-mail","maxlength":"100","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.enq_Email),callback:function ($$v) {_vm.$set(_vm.fdata, "enq_Email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.enq_Email"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":{ max: 1000, required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"color":"blue lighten-1","label":"Your enquiry *","maxlength":"1000","error-messages":errors,"clearable":"","outlined":""},model:{value:(_vm.fdata.enq_Message),callback:function ($$v) {_vm.$set(_vm.fdata, "enq_Message", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fdata.enq_Message"}})]}}])})],1),_c('v-col',{staticClass:"py-1 text-center",attrs:{"cols":"12","justify-content":""}},[_c('v-btn',{attrs:{"loading":_vm.showBusyAni,"color":"primary","x-large":"","dark":"","width":"150"},on:{"click":_vm.saveFormData}},[_vm._v("Submit")])],1)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.showSnackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }